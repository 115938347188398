<template>
  <footer class="footer">
    <div class="nav">
      <div class="nav__item"><router-link to="/terms" class="nav__item-link">Публичная оферта</router-link></div>
      <div class="nav__item"><router-link to="/unsub" class="nav__item-link">Управление подпиской</router-link></div>
      <div class="nav__item"><router-link to="/price" class="nav__item-link">Стоимость услуги</router-link></div>
      <div class="nav__item"><router-link to="/contacts" class="nav__item-link">Контакты</router-link></div>
    </div>
    <div class="copyright">Copyrigth {{ copyrightYear }} Baydoo.org. Все права защищены.</div>
  </footer>
</template>

<script>
  export default {
  	data() {
  		return {
  			initial: 2022
      }
    },
  	computed: {
		  copyrightYear() {
		  	let currentDate = new Date().getFullYear();

		  	if (this.initial === currentDate) {
		  		return this.initial;
        }

		  	return `${this.initial}-${currentDate}`;
      }
    }
  }
</script>